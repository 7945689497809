@if (label) {
	<fieldset>
		<legend>{{ label }}</legend>
		<ng-container *ngTemplateOutlet="checkboxGroupContent" />
	</fieldset>
} @else {
	<ng-container *ngTemplateOutlet="checkboxGroupContent" />
}

<ng-template #checkboxGroupContent>
	@for (option of options(); track option.id) {
		<mat-checkbox
			[ngStyle]="{ 'min-width': columnWidth() }"
			[checked]="isChecked(option)"
			(change)="onCheckboxChange($event, option.id)">
			{{ option.label }}
		</mat-checkbox>
	}
</ng-template>
